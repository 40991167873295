import * as React from "react"
import Layout from '../components/layout'

// markup
const NotFoundPage = () => {
  return (
    <Layout>
      <h1>PAGE NOT FOUND</h1>
    </Layout>
  )
}

export default NotFoundPage
